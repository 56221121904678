import {booleanAttribute, ChangeDetectionStrategy, Component, forwardRef, input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidatorFn} from '@angular/forms';

@Component({
  selector: 'app-base-input',
  templateUrl: './base-input.component.html',
  styleUrls: ['./base-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseInputComponent),
      multi: true,
    },
  ],
  imports: [
    ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseInputComponent implements ControlValueAccessor, OnInit {
  protected childValidators: ValidatorFn[] = [];
  validators = input<ValidatorFn[]>([]);
  control = input.required<FormControl>(); // Receives the control from parent
  label = input<string>(); // Receives the control from parent
  placeholder = input<string>('');
  disabled = input(false, {transform: booleanAttribute});

  ngOnInit(): void {
    // Add child-specific validators (e.g., minLength, pattern) to the parent control
    const control = this.control();
    const parentValidators = control.validator ? [control.validator] : [];
    control.setValidators([...this.childValidators, ...this.validators(), ...parentValidators]);
    control.updateValueAndValidity();
  }

  writeValue(value: any): void {
    this.control().setValue(value);
  }

  registerOnChange(fn: any): void {
    this.control().valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.control().markAsTouched(fn);
  }
}
